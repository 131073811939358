import type { IconTypes } from "solid-icons";
import { CustomSolidIcon } from "./CustomSolidIcon";

export const StFileNoExtension: IconTypes = (props) =>
  CustomSolidIcon(
    {
      a: { viewBox: "0 0 24 16", fill: "none" },
      c: `<path d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 12V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>`,
    },
    props,
  );
