import { Tabs } from "@kobalte/core";
import { TbDatabase, TbMessage, TbSettings, TbSettingsAutomation, TbSparkles } from "solid-icons/tb";
import { type Component, For, type Setter, Show, createMemo } from "solid-js";
import { twMerge } from "tailwind-merge";
import { UpcomingFeature } from "~/components/UpcomingFeature";
import { StIcon } from "~/components/icons";
import { type Wire, useWire } from "~/wire";

const getTabs = (wire: Wire) => [
  {
    label: wire.dict.ctxHeader.tabs.smartChat,
    icon: TbMessage,
    activeBg: "bg-orange-600",
    activeText: "text-orange-600",
    gated: false,
  },
  {
    label: wire.dict.ctxHeader.tabs.knowledge,
    icon: TbDatabase,
    activeBg: "bg-red-600",
    activeText: "text-red-600",
    gated: true,
  },
  {
    label: wire.dict.ctxHeader.tabs.actions,
    icon: TbSettingsAutomation,
    activeBg: "bg-blue-600",
    activeText: "text-blue-600",
    gated: true,
  },
  {
    label: wire.dict.ctxHeader.tabs.insights,
    icon: TbSparkles,
    activeBg: "bg-pink-600",
    activeText: "text-pink-600",
    gated: true,
  },
  {
    label: wire.dict.ctxHeader.tabs.settings,
    icon: TbSettings,
    activeBg: "bg-pink-600",
    activeText: "text-pink-600",
    gated: true,
  },
];

export const ThreadTabs: Component<{
  tab: string | undefined;
  setTab: Setter<string | undefined>;
}> = (props) => {
  const wire = useWire();
  const tabs = createMemo(() => getTabs(wire));

  props.setTab(tabs()[0]?.label);

  return (
    <div class="select-none h-full flex items-center px-4 sm:px-2">
      <Tabs.Root value={props.tab} onChange={props.setTab} class="rounded-lg p-1">
        <Tabs.List class="relative flex gap-4">
          <Tabs.Indicator
            class={twMerge("absolute transition-all duration-300 h-[3px] bg-indigo-700 rounded-full -bottom-3")}
          />
          <For each={tabs()}>
            {(t) => (
              <Show
                when={!t.gated}
                fallback={
                  <UpcomingFeature
                    text={t.label === "Knowledge" ? "Managing project knowledge coming soon" : undefined}
                    label={`Thread ${t.label}`}
                  >
                    <span
                      class={twMerge(
                        "flex items-center gap-2 relative px-3 py-1 transition-all dark:text-white rounded-lg text-xs md:text-base",
                      )}
                    >
                      <StIcon icon={t.icon} />
                      {t.label}
                    </span>
                  </UpcomingFeature>
                }
              >
                <Tabs.Trigger
                  class={twMerge(
                    "flex items-center gap-2 relative px-3 py-1 transition-all dark:text-white rounded-lg text-xs md:text-base",
                    t.label === props.tab ? "font-semibold text-indigo-800" : undefined,
                  )}
                  value={t.label}
                >
                  <StIcon
                    icon={t.icon}
                    classList={{
                      "stroke-3": t.label === props.tab,
                    }}
                  />
                  {t.label}
                </Tabs.Trigger>
              </Show>
            )}
          </For>
        </Tabs.List>
      </Tabs.Root>
    </div>
  );
};
