import { type Component, Show, For } from "solid-js";

export const HighlightText: Component<{
  text: string;
  search: string;
  highlightClass: string;
}> = (props) => {
  const parts = () => {
    if (!props.search) return [];

    const index = props.text.toLowerCase().indexOf(props.search.toLowerCase());

    if (index === -1) return [{ type: "text", text: props.text }];

    return [
      { type: "text", text: props.text.substring(0, index) },
      {
        type: "highlight",
        text: props.text.substring(index, index + props.search.length),
      },
      { type: "text", text: props.text.substring(index + props.search.length) },
    ];
  };

  return (
    <Show when={props.search} fallback={<span>{props.text}</span>}>
      <For each={parts()}>
        {(p) => (
          <span
            classList={{
              [props.highlightClass]: p.type === "highlight",
            }}
          >
            {p.text}
          </span>
        )}
      </For>
    </Show>
  );
};
