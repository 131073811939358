import { TbX } from "solid-icons/tb";
import { onCleanup, onMount, type ParentComponent, Show } from "solid-js";
import { Motion, Presence } from "solid-motionone";
import { StButton } from "~/components/buttons";
import { useBreakpoints } from "~/lib/useBreakPoints";

export const KnowledgePanelModal: ParentComponent<{
  show: boolean;
  onHide: () => void;
}> = (props) => {
  const breakpoints = useBreakpoints();

  onMount(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") props.onHide();
    };
    document.addEventListener("keydown", listener);
    onCleanup(() => {
      document.removeEventListener("keydown", listener);
    });
  });

  return (
    <>
      <Show when={props.show}>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <div
          aria-hidden
          class="hidden md:block fixed inset-0 bg-black/20 dark:bg-white/10 z-50 animate-fade-in"
          onClick={() => {
            props.onHide();
          }}
        />
      </Show>
      <Presence>
        <Show when={props.show}>
          <Motion.div
            initial={{
              x: 200,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{
              x: 200,
              opacity: 0,
            }}
            class="max-w-screen-2xl fixed inset-0 md:right-0 md:top-20 md:bottom-10 md:left-auto z-50 md:w-[85vw] md:max-h-[calc(100vh_-_5rem)] overflow-y-auto bg-white border dark:border-indigo-800 dark:bg-indigo-1100 md:shadow-2xl md:rounded-l-lg"
          >
            <div class="flex justify-end px-5 pb-4 pt-4 -mb-8 sticky top-0 bg-white dark:bg-indigo-1100 z-10">
              <StButton onClick={props.onHide} icon={TbX} size={breakpoints.md ? "md" : "sm"} />
            </div>
            {props.children}
          </Motion.div>
        </Show>
      </Presence>
    </>
  );
};
