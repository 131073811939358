import type { q } from "@repo/client";
import type { Component, JSXElement } from "solid-js";
import { HorizontalDivider } from "~/components/dividers";
import { StIcon, StFileNoExtension } from "~/components/icons";
import { Tooltip, TooltipTrigger } from "~/components/popups/Tooltip";
import { HighlightText } from "./HighlightText";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export const KnowledgePanelAssetRow: Component<{
  asset: q.ControlplaneSsAsset;
  search: string;
  actions?: JSXElement;
}> = (props) => {
  const fileType = (asset: q.ControlplaneSsAsset) => asset.originalFilename.split(".").pop()?.toUpperCase() || "";

  return (
    <>
      <HorizontalDivider class="col-span-full" />

      <div class="flex justify-center items-center text-xs md:text-sm">{props.actions}</div>
      <div class="flex items-center justify-center">
        <div
          class="flex flex-col items-center justify-center scale-75 md:scale-100"
          classList={{
            "text-red-500 dark:text-red-400": fileType(props.asset) === "PDF",
          }}
        >
          <StIcon icon={StFileNoExtension} class="mt-2 size-6" />

          <span class="relative bottom-2 text-2xs font-bold">{fileType(props.asset)}</span>
        </div>
      </div>

      <div class="flex flex-col justify-center max-w-52 md:max-w-72">
        <Tooltip theme="invert" placement="top" content={<span>{props.asset.displayName}</span>}>
          <span class="truncate">
            <TooltipTrigger as="h3" class="font-medium inline text-sm md:text-base">
              <HighlightText
                search={props.search}
                text={props.asset.displayName}
                highlightClass="font-bold bg-yellow-200 dark:bg-yellow-800"
              />
            </TooltipTrigger>
          </span>
        </Tooltip>
        <p class="text-2xs md:text-sm text-slate-600 dark:text-indigo-200 truncate">
          <HighlightText
            search={props.search}
            text={props.asset.originalFilename}
            highlightClass="font-medium bg-yellow-200 dark:bg-yellow-800"
          />
        </p>
      </div>
      <div class="max-w-full flex items-center">
        <p class="line-clamp-2 text-xs md:text-sm">
          <HighlightText
            search={props.search}
            text={props.asset.summary || "No summary"}
            highlightClass="font-medium bg-yellow-200 dark:bg-yellow-800"
          />
        </p>
      </div>
      <div class="flex items-center text-xs md:text-sm">
        {dayjs(props.asset.modifiedAt || props.asset.createdAt).format("LL")}
      </div>
      <div class="flex justify-center items-center text-xs md:text-sm">{props.asset.userDisplayName}</div>
      <div aria-hidden />
    </>
  );
};
