import type { threads } from "@repo/client";
import { debounce } from "@solid-primitives/scheduled";
import { type Component, For, Show, createSignal, onCleanup, onMount } from "solid-js";
import type { DOMElement } from "solid-js/jsx-runtime";
import { Motion } from "solid-motionone";
import { twMerge } from "tailwind-merge";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { TextSelectionMenu } from "../TextSelectionMenu";
import "./codeHighlightTheme.css";
import { MarkdownRenderer } from "./MarkdownRenderer";
import { TextUnitV1ActionsBar } from "./TextUnitV1ActionsBar";
import { TextUnitV1TextSelection } from "./TextUnitV1TextSelection";

export type TextInstructionUnitV1Props = {
  message: threads.MessageTextV1;
  class?: string;
  disableActions?: boolean;
};

export const TextUnitV1 = (props: TextInstructionUnitV1Props) => {
  const { editor, typePrompt } = usePromptContext();
  const [ref, setRef] = createSignal<HTMLElement>();

  return (
    <Motion.div
      // {...getUnitAnimationConfig()}
      class="dark:text-slate-200 text-gray-800 text-sm md:text-base scroll-mt-32"
      data-block={props.message.messageId}
    >
      <div ref={setRef} class={twMerge("flex flex-col break-words", props.class)}>
        <TextUnitV1Markdown message={props.message} />
      </div>

      <TextUnitV1TextSelection ref={ref} />

      <Show when={!props.disableActions}>
        <TextUnitV1ActionsBar message={props.message} />

        <Show when={props.message.textSuggestions}>
          <div>
            <span class="text-[10px] text-violet-500 dark:text-violet-400 underline underline-offset-4 uppercase tracking-wider">
              Prompt Suggestions:
            </span>
            <ul class="block list-disc ml-4 pt-2">
              <For each={props.message.textSuggestions}>
                {(suggestion) => (
                  <li class="dark:text-purple-400 text-black pl-2 block text-left mb-4">
                    <button
                      class="text-left hover:underline underline-offset-2"
                      type={"button"}
                      onClick={() => {
                        editor()?.commands.focus();
                        typePrompt(suggestion, { highlight: true });
                      }}
                    >
                      {suggestion}
                    </button>
                  </li>
                )}
              </For>
            </ul>
          </div>
        </Show>
      </Show>
    </Motion.div>
  );
};

const TextUnitV1Markdown: Component<{
  message: threads.MessageTextV1;
}> = (props) => {
  const [el, setEl] = createSignal<HTMLElement>();
  const [domRect, setDomRect] = createSignal<DOMRect>();
  const [range, setRange] = createSignal<Range>();

  // Use prevent hide to keep the dropdown alive when a user
  // exits all markdown elements but is hovering the mouse on the dropdown
  let preventHide = false;
  // Used to block the dropdown from showing when the text selction dropdown is out
  let preventShow = false;

  // Debouncing it to prevent flickering when switching in between elements fast
  const setRectDebounced = debounce((value: DOMRect | undefined) => setDomRect(value), 500);

  // The hover text modal is different from
  onMount(() => {
    const listener = () => {
      const selection = getSelection();
      if (selection?.rangeCount && selection?.rangeCount > 0) {
        setRange(selection?.getRangeAt(0));
      }
      if ((selection?.toString() || "") !== "") {
        setRectDebounced.clear();
        setDomRect();
        preventShow = true;
      } else {
        preventShow = false;
      }
    };
    const focusOut = (event: FocusEvent) => {
      const _range = range();
      if (_range && !_range?.collapsed) {
        if (event.relatedTarget instanceof HTMLElement && event.relatedTarget.id.includes("dropdownmenu")) {
          getSelection()?.removeAllRanges();
          getSelection()?.addRange(_range);
        }
      }
    };
    document.addEventListener("selectionchange", listener);
    document.addEventListener("focusout", focusOut);
    onCleanup(() => {
      document.removeEventListener("selectionchange", listener);
      document.removeEventListener("focusout", focusOut);
    });
  });

  const onHover = (
    e: MouseEvent & {
      target: DOMElement;
    },
  ) => {
    if (preventShow) return;
    if (!(e.target instanceof HTMLElement)) return;
    setEl(e.target);
    setRectDebounced.clear();
    const elRect = e.target.getBoundingClientRect();

    const messageEl = document.querySelector(`[data-block="${props.message.messageId}"]`);
    const messageRect = messageEl?.getBoundingClientRect() ?? elRect;

    setRectDebounced({
      top: elRect.top,
      height: elRect.height,
      bottom: elRect.bottom,
      y: elRect.y,
      left: messageRect.left,
      right: messageRect.right,
      width: messageRect.width,
      x: messageRect.x,
      toJSON: elRect.toJSON,
    });
  };

  const onUnhover = () => {
    if (preventHide) return;
    setRectDebounced(undefined);
  };

  return (
    <>
      <TextSelectionMenu
        rect={domRect()}
        from="block"
        animatedMovement
        onClose={() => {
          preventHide = false;
          setRectDebounced.clear();
          setDomRect();
        }}
        opts={{
          preventScroll: false,
          placement: "right-start",
          hideWhenDetached: true,
        }}
        content={{
          onMouseEnter: () => {
            preventHide = true;
            setRectDebounced.clear();
          },
          onMouseLeave: (e) => {
            if ("toElement" in e && e.toElement instanceof HTMLElement) {
              const id = e.toElement.id;
              if (!id.includes("dropdownmenu")) {
                preventShow = true;
                preventHide = false;
                onUnhover();
                setTimeout(() => {
                  preventShow = false;
                }, 510);
                return;
              }
            }

            preventHide = false;
            onUnhover();
          },
        }}
        getText={() => el()?.innerText || ""}
        getEl={el}
      />

      <MarkdownRenderer
        prefix={props.message.messageId}
        md={props.message.parts.join("\n")}
        onMouseOverChild={onHover}
        onMouseOutChild={onUnhover}
      />
    </>
  );
};
