import { TbSortAscending, TbSortDescending } from "solid-icons/tb";
import type { Component } from "solid-js";
import { StButton } from "~/components/buttons";
import { useBreakpoints } from "~/lib/useBreakPoints";
import type { SortBy, SortDir } from "./KnowledgePanel";

export const KnowledgePanelColumnTitlesRow: Component<{
  changeSorting: (val: SortBy) => void;
  sortBy: SortBy;
  sortDir: SortDir;
}> = (props) => {
  const breakpoints = useBreakpoints();

  const getSortIcon = (name: SortBy) =>
    props.sortBy === name ? (props.sortDir === "asc" ? TbSortAscending : TbSortDescending) : undefined;

  const size = () => (breakpoints.md ? "md" : "sm");

  return (
    <div class="contents font-semibold text-slate-700">
      <div aria-hidden />
      <StButton
        icon={getSortIcon("file_type")}
        simple
        size={size()}
        class="text-left hover:bg-transparent dark:hover:bg-transparent px-0"
        type="button"
        onClick={() => {
          props.changeSorting("file_type");
        }}
      >
        Type
      </StButton>
      <StButton
        icon={getSortIcon("name")}
        simple
        size={size()}
        class="text-left hover:bg-transparent dark:hover:bg-transparent justify-start px-0"
        type="button"
        onClick={() => {
          props.changeSorting("name");
        }}
      >
        Name
      </StButton>
      <StButton
        icon={getSortIcon("summary")}
        simple
        size={size()}
        class="text-left hover:bg-transparent dark:hover:bg-transparent justify-start px-0"
        type="button"
        onClick={() => {
          props.changeSorting("summary");
        }}
      >
        Summary
      </StButton>
      <StButton
        icon={getSortIcon("last_updated")}
        simple
        size={size()}
        class="text-left hover:bg-transparent dark:hover:bg-transparent justify-start px-0"
        type="button"
        onClick={() => {
          props.changeSorting("last_updated");
        }}
      >
        Last updated
      </StButton>
      <StButton
        icon={getSortIcon("created_by")}
        simple
        size={size()}
        class="text-left hover:bg-transparent dark:hover:bg-transparent justify-start px-0"
        type="button"
        onClick={() => {
          props.changeSorting("created_by");
        }}
      >
        Created by
      </StButton>
      <div aria-hidden />
    </div>
  );
};
