import { stAnalytics } from "@repo/analytics";
import type { operations, q } from "@repo/client";
import { A, useLocation, useNavigate } from "@solidjs/router";
import dayjs from "dayjs";
import type { IconTypes } from "solid-icons";
import { TbBolt, TbCaretRight, TbFile, TbHourglassHigh, TbMinus, TbPlus, TbTrash } from "solid-icons/tb";
import {
  type Component,
  For,
  type JSXElement,
  type ParentComponent,
  Show,
  Suspense,
  createEffect,
  createMemo,
  createSignal,
  on,
} from "solid-js";
import { createStore } from "solid-js/store";
import { Dynamic, isServer } from "solid-js/web";
import { twMerge } from "tailwind-merge";
import { Spinner } from "~/components/Loaders";
import { UpcomingFeature } from "~/components/UpcomingFeature";
import { StButton } from "~/components/buttons";
import { StIcon } from "~/components/icons";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { SignUpCTA } from "~/domains/identity/components/SignUpCTA";
import { NewThreadIdIndicator } from "~/domains/threads";
import { urls } from "~/lib/urls";
import { useBreakpoints } from "~/lib/useBreakPoints";
import { useWire } from "~/wire";

const threadsByRecent = (threads: operations.Response<q.GetRecentThreadsForUserRow[]> | undefined) => {
  const today: q.GetRecentThreadsForUserRow[] = [];
  const yesterday: q.GetRecentThreadsForUserRow[] = [];
  const week: q.GetRecentThreadsForUserRow[] = [];
  const month: q.GetRecentThreadsForUserRow[] = [];

  threads?.data?.forEach((item) => {
    const date = dayjs(item.accessedAt);
    const diffDays = date.diff(dayjs(), "day");
    if (diffDays <= 1) today.push(item);
    else if (diffDays <= 2) yesterday.push(item);
    else if (diffDays <= 7) week.push(item);
    else if (diffDays <= 30) month.push(item);
  });

  return [
    ...(today.length
      ? [
          {
            label: "Today",
            children: today,
          },
        ]
      : []),
    ...(yesterday.length
      ? [
          {
            label: "Yesterday",
            children: yesterday,
          },
        ]
      : []),
    ...(week.length
      ? [
          {
            label: "Last week",
            children: week,
          },
        ]
      : []),
    ...(month.length
      ? [
          {
            label: "Last month",
            children: month,
          },
        ]
      : []),
  ];
};

export const TreeNavSidebar: Component<{
  threads: operations.Response<q.GetRecentThreadsForUserRow[]> | undefined;
  assets: operations.Response<q.ControlplaneSsAsset[]> | undefined;
  showKnowledge: boolean;
  setShowKnowledge: (t: boolean) => void;
}> = (props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const location = useLocation();

  const breakpoints = useBreakpoints();
  const closeSideBar = () => {
    if (!breakpoints.md) {
      wire.services.appLayout.setData("leftSidebar", { closed: true, size: 0 });
    }
  };

  const [search, setSearch] = createSignal("");
  const [expanded, setExpanded] = createStore({
    workspace: false,
    "workspace.recents": true,
    "workspace.threads": false,
    knowledge: true,
    "knowledge.powering": true,
    "knowledge.recents": true,
  });

  const onNewThread = async () => {
    if (!wire.services.identity.snapshot.context.identity.isAuthenticated) {
      return;
    }
    const projectId = wire.services.identity.snapshot.context.identity.workingContext.projectId;
    navigate(urls.thread(projectId, NewThreadIdIndicator));
  };

  const getThreadProps = (item: q.GetRecentThreadsForUserRow) => {
    const url = urls.thread(wire.services.identity.snapshot.context.identity.workingContext.projectId, item.threadId);
    const active = location.pathname === url;
    return {
      url,
      active,
    };
  };

  return (
    <div class="text-sm w-full overflow-x-hidden overflow-y-auto pt-3">
      <div class="py-2 pl-2 pr-2 flex flex-col gap-6">
        <div class="flex flex-col gap-1">
          <TreeItem
            expandable
            expanded={expanded["workspace.recents"]}
            onClick={() => setExpanded("workspace.recents", (w) => !w)}
            label="My Project"
            class="text-2xs font-thin tracking-wider"
            actions={
              <StButton
                class="whitespace-pre"
                size="xs"
                label="New Thread"
                icon={TbPlus}
                onClick={(e) => {
                  e.stopPropagation();
                  onNewThread();
                  stAnalytics.track("new_thread_clicked", undefined);
                  closeSideBar();
                }}
              >
                New Thread
              </StButton>
            }
          />
          <Suspense
            fallback={
              <div class="grid place-content-center h-20">
                <Spinner class="size-6" />
              </div>
            }
          >
            <TreeDrawer flat expanded={expanded["workspace.recents"]}>
              <For each={threadsByRecent(props.threads)}>
                {(group) => (
                  <>
                    <span class="text-[10px] tracking-wider uppercase text-slate-400 dark:text-indigo-100/50 px-2 -mb-2">
                      {group.label}
                    </span>
                    <For each={group.children}>
                      {(item) => (
                        <>
                          <TreeItem
                            label={item.label}
                            onClick={() => {
                              props.setShowKnowledge(false);
                              closeSideBar();
                            }}
                            {...getThreadProps(item)}
                            hasActiveChild={props.showKnowledge}
                            actions={
                              <StButton
                                onClick={async (e) => {
                                  e.preventDefault();
                                  e.stopImmediatePropagation();
                                  if (window.confirm(`Are you sure you want to delete "${item.label}"`)) {
                                    await wire.services.threads.deleteThread(item.threadId);

                                    const latestThread = props.threads?.data[0]?.threadId ?? NewThreadIdIndicator;

                                    navigate(
                                      urls.thread(
                                        wire.services.identity.snapshot.context.identity.workingContext.projectId,
                                        latestThread,
                                      ),
                                    );
                                  }
                                }}
                                label="Delete thread"
                                icon={TbTrash}
                                size="xs"
                                class="flex md:hidden md:group-hover:flex"
                              >
                                Delete
                              </StButton>
                            }
                          />

                          <TreeDrawer expanded={getThreadProps(item).active}>
                            <TreeItem
                              label="Knowledge"
                              active={props.showKnowledge}
                              onClick={() => {
                                props.setShowKnowledge(true);
                                closeSideBar();
                              }}
                            />
                          </TreeDrawer>
                        </>
                      )}
                    </For>
                  </>
                )}
              </For>
            </TreeDrawer>
          </Suspense>
        </div>

        <UpcomingFeature label={"Create new project"}>
          <StButton
            simple
            icon={TbPlus}
            onClick={() => {
              stAnalytics.track("new_project_clicked", undefined);
            }}
          >
            New Project
          </StButton>
        </UpcomingFeature>

        <SignUpMessage />
      </div>
    </div>
  );
};

const SignUpMessage = () => {
  const wire = useWire();

  const { threadEventProps } = useThreadEventProperties();
  const interactionsCount = wire.services.limiting.guest.interactionsCount;

  const isGuestMemo = createMemo(() => wire.services.identity.snapshot.context.identity.isGuest);
  createEffect(
    on(isGuestMemo, (isGuest) => {
      if (isGuest)
        stAnalytics.track("thread_sidebar_sign_up_shown", {
          ...threadEventProps(),
          interactionsCount: interactionsCount(),
        });
    }),
  );

  return (
    <Show when={isGuestMemo()}>
      <Divider />
      <div class="relative px-2 rounded dark:text-white">
        <div>
          <p class="font-medium mb-4 text-base">Want to save your progress? Create an account with your work email.</p>
        </div>

        <div class="relative">
          <SignUpCTA
            class="font-medium px-10 bg-violet-800 hover:bg-violet-700 dark:bg-white dark:hover:bg-slate-100 text-white dark:text-slate-900 w-full text-nowrap"
            tracking={() => {
              stAnalytics.track("thread_sidebar_sign_up_clicked", {
                ...threadEventProps(),
                interactionsCount: interactionsCount(),
              });
              stAnalytics.track("sign_up_clicked", undefined);
            }}
          />
        </div>
      </div>
    </Show>
  );
};

export const AssetsSidebar: Component<{
  assets: operations.Response<q.ControlplaneSsAsset[]> | undefined;
  setShowKnowledge: (k: boolean) => void;
}> = (props) => {
  const wire = useWire();
  const { setShowUploadModal, changeKnowledge } = usePromptContext();

  const breakpoints = useBreakpoints();
  const closeSideBar = () => {
    if (!breakpoints.md) {
      wire.services.appLayout.setData("rightSidebar", {
        closed: true,
        size: 0,
      });
    }
  };

  const [expanded, setExpanded] = createStore({
    "knowledge.powering": true,
    "knowledge.recents": true,
  });

  const assets = createMemo(() => {
    const all = props.assets?.data;
    if (!all)
      return {
        used: [],
        unused: [],
      };

    const inThread = wire.services.threads.snapshot.context.activeAssets;

    const used: q.ControlplaneSsAsset[] = [];
    const unused: q.ControlplaneSsAsset[] = [];

    all.forEach((a) => {
      if (inThread?.find((t) => t.id === a.id)) {
        used.push(a);
      } else {
        unused.push(a);
      }
    });
    return {
      used,
      unused,
    };
  });

  const onAdd = async (asset: string) => {
    wire.services.knowledge.addQueuedKnowledgeChange(asset);
    await changeKnowledge();
    stAnalytics.track("thread_sidebar_add_asset_clicked", { asset_id: asset });
    wire.services.knowledge.resetQueuedKnowledgeChange();
  };
  const onRemove = async (asset: string) => {
    wire.services.knowledge.removeQueuedKnowledgeChange(asset);
    await changeKnowledge();
    stAnalytics.track("thread_sidebar_remove_asset_clicked", {
      asset_id: asset,
    });
    wire.services.knowledge.resetQueuedKnowledgeChange();
  };

  return (
    <div class="flex flex-col gap-1 text-sm pt-3">
      <StButton
        class="mx-4 mb-6 mt-3"
        size="sm"
        onClick={() => {
          props.setShowKnowledge(true);
          closeSideBar();
        }}
      >
        Change knowledge
      </StButton>

      <Suspense
        fallback={
          <div class="grid place-content-center h-20">
            <Spinner class="size-6" />
          </div>
        }
      >
        <TreeDrawer flat expanded>
          <TreeItem
            expandable
            expanded={expanded["knowledge.powering"]}
            onClick={() => setExpanded("knowledge.powering", (w) => !w)}
            icon={TbBolt}
            label="You're interacting with"
          />

          <TreeDrawer expanded={expanded["knowledge.powering"]}>
            <For each={assets()?.used}>
              {(item) => (
                <TreeItem
                  icon={TbFile}
                  label={item.displayName}
                  actions={
                    <StButton
                      onClick={() => onRemove(item.id)}
                      label="Remove from thread"
                      icon={TbMinus}
                      size="xs"
                      class="flex md:hidden md:group-hover:flex"
                    >
                      Remove
                    </StButton>
                  }
                />
              )}
            </For>
          </TreeDrawer>

          <TreeItem
            expandable
            expanded={expanded["knowledge.recents"]}
            onClick={() => setExpanded("knowledge.recents", (w) => !w)}
            icon={TbHourglassHigh}
            label="Want to add these recent files?"
          />

          <TreeDrawer expanded={expanded["knowledge.recents"]}>
            <For each={assets()?.unused.slice(0, 3)}>
              {(item) => (
                <TreeItem
                  icon={TbFile}
                  label={item.displayName}
                  actions={
                    <StButton
                      onClick={() => onAdd(item.id)}
                      label="Add to thread"
                      icon={TbPlus}
                      size="xs"
                      class="flex md:hidden md:group-hover:flex"
                    >
                      Add
                    </StButton>
                  }
                />
              )}
            </For>
          </TreeDrawer>
        </TreeDrawer>
      </Suspense>
    </div>
  );
};

const Divider = () => (
  <div
    aria-hidden
    class="pointer-events-none bg-gradient-to-r h-[2px] from-transparent via-violet-300/20 to-transparent"
  />
);

const TreeItem: Component<{
  label: string;
  icon?: IconTypes;
  active?: boolean;
  hasActiveChild?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  actions?: JSXElement;
  onClick?: () => void;
  class?: string;
  poweringActiveMessage?: boolean;
  url?: string;
}> = (props) => {
  return (
    <Dynamic
      component={props.url ? A : "div"}
      href={props.url}
      class={twMerge(
        "group flex items-center gap-1 hover:bg-indigo-800/10 dark:hover:bg-indigo-200/10 rounded w-full px-2 py-1 cursor-pointer select-none",
        props.class,
      )}
      onClick={props.onClick}
      classList={{
        "text-slate-700 dark:text-white": !props.active,
        "font-semibold text-indigo-800 dark:text-violet-400": !!props.active,
        "bg-indigo-600/20 hover:bg-indigo-600/30": props.active && !props.hasActiveChild,
        "font-semibold": props.poweringActiveMessage,
      }}
    >
      <Show when={props.icon}>
        <StIcon
          classList={{
            "stroke-3": props.poweringActiveMessage || props.active,
          }}
          icon={props.icon as IconTypes}
        />
      </Show>
      <span class="truncate">{props.label}</span>
      <Show when={props.expandable}>
        <StIcon
          icon={TbCaretRight}
          class="transition-transform"
          classList={{
            "rotate-90": props.expanded,
          }}
        />
      </Show>
      <div class="flex-auto" aria-hidden />
      {props.actions}
    </Dynamic>
  );
};

const TreeDrawer: ParentComponent<{ expanded: boolean; flat?: boolean }> = (props) => {
  let ref!: HTMLDivElement;
  const duration = 300;

  // First mount will prevent the animation from triggering on hydration after SSR
  // This prevents the sidebar to move around after the initial page load
  let firstMount = true;
  createEffect(() => {
    const e = props.expanded;
    if (e) {
      if (firstMount) {
        firstMount = false;
        return;
      }
      ref.style.removeProperty("transition");
      const height = ref.children[0]?.getBoundingClientRect().height || 0;
      ref.style.height = "0px";
      ref.removeAttribute("aria-hidden");

      requestAnimationFrame(() => {
        ref.style.transition = `${duration}ms`;
        ref.style.height = `${height}px`;
        setTimeout(() => {
          ref.style.removeProperty("transition");
          ref.style.removeProperty("height");
        }, duration);
      });
      return;
    }

    const height = ref.children[0]?.getBoundingClientRect().height || 0;
    ref.setAttribute("aria-hidden", "true");
    ref.style.height = `${height}px`;
    if (firstMount) {
      ref.style.height = "0px";
      firstMount = false;
    } else {
      ref.style.transition = `${duration}ms`;

      requestAnimationFrame(() => {
        ref.style.height = "0px";
      });
    }
  });
  return (
    <div
      ref={ref}
      class="overflow-y-hidden"
      style={{
        height: isServer && !props.expanded ? "0px" : undefined,
      }}
    >
      <div
        class="flex flex-col gap-1"
        classList={{
          "pl-1 ml-3 border-l-2 border-violet-200 dark:border-violet-300/30": !props.flat,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
